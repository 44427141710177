export default {
    zh:{
        order:{
            queryParam:{
                code:'意向订单号',
                code_placeholder:'请输入意向订单号',
            },
            columns:{
                F_STATUS:'状态',
                F_CODE:'意向订单号',
                F_TJ_DATE:'提交日期',
                F_TJ_USER_ID:'提交人',
            },
            form:{
                customerDeliveryId:'收货人名称',
                customerDeliveryId_placeholder:'请选择收货人名称',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
            },
        }
    },
    en:{
        order:{
            queryParam:{
                code:'意向订单号',
                code_placeholder:'请输入意向订单号',
            },
            columns:{
                F_STATUS:'状态',
                F_CODE:'意向订单号',
                F_TJ_DATE:'提交日期',
                F_TJ_USER_ID:'提交人',
            },
            form:{
                customerDeliveryId:'客户收货信息ID',
                customerDeliveryId_placeholder:'请选择客户收货信息ID',
                customerId:'客户ID',
                customerId_placeholder:'请选择客户ID',
            },
        }
    }
}